
/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color: #F3F3F3;}

.nebula-main{ color: #505ba1 !important; }
.nebula-dark{ color : #4b4b4b !important; }
.nebula-light{ color: #F3F3F3 !important; }


.nebula-main-background{ background-color: #505ba1 !important; }
.nebula-dark-background{ background-color : #4b4b4b !important; }
.nebula-light-background{ background-color: #F3F3F3 !important; }


.mat-dialog-container {
  border-radius: 10px !important;
}

.assettitle{
  font-size: 1.64rem !important;
  text-align: left !important;
  color: #4b4b4b !important;
}

.maincontainer{
  height: 100%;
  width: 100%;
  text-align: center !important;
}

.full-container{
  height: 100%;
  width: 100%;
}

.appcontainer{
  margin-left: 5% !important;
  width: 90% !important;
  margin-top: 2rem !important;
  height: 50rem !important;
}

a{
  text-decoration: none !important;
}

ul{
  list-style-type: none !important;
}

.underline:hover{
  text-decoration: underline;
}

.underline-text-nebula {
  display: inline-block;
  border-bottom: 4px solid #505ba1;
}


.image {
  height: 100%;
  width: 100% !important;
  z-index: 0;
  /* margin: 10px; */
}

.menubtn{
  width: 45px !important;
  height: 45px !important;
  //background-color: #505ba1 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.utilbtn{
  width: 35px !important;
  height: 35px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

//posizione
.center-horizzontal{
  text-align: center;
}

.center-vertical{
  vertical-align: middle;
}

.left {
  display: block;
  float: left !important;
}

.right {
  position: absolute;
  right: 52px;
  top: 22px;
  z-index: 1000;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}



.index{
  font-size: 12px;
  font-weight: bold;
}

.profile-circle {
  width: 43px;
  height: 42px;
  margin-top: -5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  border: 2px solid #eee;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
  vertical-align: middle;
  object-fit: contain;
}

mat-card{
  border-radius: 10px !important;
}

.leaflet-popup-close-button{
  display: none;
}
